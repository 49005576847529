import { getServerHelpers } from "../../helpers";
import { registerInAppRevenueCatCurrentEntitlements } from "../../internal-utils/license-utils";
import { validateToken } from "../../internal-utils/server-auth";

export * from "./license__getLicenseNoAuth";
export * from "./license__getLicensesNoAuth";
export * from "./license__addTrialLicense";
export * from "./license__getSubscription";

export async function license__server__inAppLicenseCelebrate(p: { accountId: string; plan: string }) {
  // SERVER_ONLY_TOGGLE
  // SERVER_ONLY_TOGGLE
}

// -------------
// pass through apis
// -------------
export async function license__server__registerInAppRevenueCatCurrentEntitlements(
  p: Parameters<typeof registerInAppRevenueCatCurrentEntitlements>[0]
) {
  //Keep this function alive until all clients have migrated away from calling it or until we bump the API number...
}
license__server__registerInAppRevenueCatCurrentEntitlements.auth = async (req: any) => {};

// i18n certified - complete
